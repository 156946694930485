<template>
  <router-view />
</template>

<script>
// This component is used as a wrapper
// to handle language based routing.
export default {
  name: 'LayoutsRoot'
};
</script>
